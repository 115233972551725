import styled from 'styled-components'
import { Link } from 'gatsby'
import { breakpoints } from 'src/utils/styles/global-styles'

export const Stars = styled(Link)`
  text-decoration: none;
  .yotpo-sr-bottom-line-text {
    font-family: 'FilsonProBook' !important;
    cursor: pointer !important;
    font-size: 20px !important;
  }
  .star-container {
    width: 20px !important;
    height: 20px !important;
  }
  @media (max-width: ${breakpoints.s}px) {
    .yotpo-bottom-line-scroll-panel {
      justify-content: center !important;
    }
    .yotpo-sr-bottom-line-text {
      font-size: 14px !important;
    }
    .star-container {
      width: 14px !important;
      height: 14px !important;
    }
  }
`

export const ENGLISH_FAQ_QUESTIONS = [
  {
    sectionTitle: 'How It Works',
    content: [
      {
        q: 'How do I activate my frame?',
        a: 'Your Skylight will come with activation instructions to get you set up in less than a minute either by app or by web.',
        link: 'https://skylight.zendesk.com/hc/en-us/articles/360023140491-How-do-I-activate-my-frame',
        text: 'See this article for step-by-step instructions.',
      },
      {
        q: 'How do I send photos to a Skylight Frame?',
        a: `There are three ways to send photos.
        1. By email. Simply draft an email with your unique Skylight email address (e.g. grandmaskylight@ourskylight.com) as the recipient and include the photos as attachments. The photos will appear on your Skylight in seconds!
        2. With the app. The Skylight app is available to download in iOS and Google Play stores for free.
        3. On the web. Log in to upload new photos and manage your Skylight devices. `,
      },
      {
        q: 'Is email the only way to send a photo?',
        a: 'Nope! You can use our web-based Cloud Portal (app.ourskylight.com) or the free mobile app to send photos and videos.',
      },
      {
        q: 'Can others send photos to Skylight?',
        a: `It's easy to share with friends and family. Send them a link from the app or share your unique Skylight email address with others so they can email photos to your Skylight.`,
      },
      {
        q: 'How many photos can Skylight hold?',
        a: 'Skylight is equipped with 8GB of storage, which is room for up to ~8,000 photos. Skylight Frame 2 has 16GB of onboard storage to display larger photo sizes at higher resolution, while also enabling more photos (up to ~16,000 photos) and videos to be stored.',
      },
      {
        q: 'How many photos can I send at a time?',
        a: 'When sending via email, you can attach up to 30MB of content to share with Skylight. If you are using our mobile app or online Cloud Portal (app.ourskylight.com), you can share up to 100MB of content at a time.',
      },
      {
        q: 'Can I send photos to multiple frames at once?',
        a: 'Yes! You can select multiple frames on the app or include multiple unique Skylight address in the recipient line of your email.',
      },
      {
        q: 'Does the Skylight Frame have a battery?',
        a: 'Skylight will only work if it is plugged in. There is a small button battery that helps protect the Skylight during electrical grid outages.',
      },
    ],
  },
  {
    sectionTitle: 'Gifting Skylight',
    content: [
      {
        q: 'Can I set up Skylight before gifting it to someone?',
        a: 'Yes! Skylight Frames make the perfect gift and Gift Mode makes it easy to preload photos and videos without ever unboxing the Frame!',
        link: 'https://skylight.zendesk.com/hc/en-us/articles/14986527892635-How-do-I-pre-load-photos-on-a-Skylight-Frame-for-a-gift',
        text: 'Find step-by-step instructions here.',
      },
      {
        q: `What if the person I'm gifting Skylight to doesn't have Wi-Fi?`,
        a: 'Content will display on Skylight with or without a Wi-Fi connection, but it requires WiFi for initial setup and to receive new photos. Any photos sent to Skylight while it is disconnected will queue for download until it is connected once more.',
      },
      {
        q: 'Do you have a gift option in your orders?',
        a: 'Skylight comes in a beautiful, ready-to-gift box. We do not yet have gift wrapping available or the ability to add a message to your order on our website, but we are working on it!',
      },
    ],
  },
]

export const USA_FAQ_QUESTIONS = [
  ...ENGLISH_FAQ_QUESTIONS,
  {
    sectionTitle: 'Frame 2',
    content: [
      {
        q: `What's new with Frame 2?`,
        a: 'Frame 2 comes with a full HD display with auto brightness, interchangeable Snap Frames that allow you change up the color and style, incresed storage space, sending video and captions is included (no Plus subscription required), Frame Plus for Frame 2 will include Doodles and more.',
      },
      {
        q: `What Frame 2 styles are available?`,
        a: 'There are 4 different frame style options: Classic, Cover, Shadow Box, and Wood. Each style has at least two color options – mix and match or gift multiple styles!',
      },
      {
        q: 'Will my Frame 2 device come with a Snap Frame?',
        a: 'Yes, each Frame 2 device comes with a Snap Frame of your choosing. Simply select the style and color after you select "Frame 2" on the product page. You can purchase additional Snap Frames during the checkout process, or via our "accessories page."',
      },
      {
        q: 'How do I remove my Snap Frame and put another on?',
        a: 'Snap Frames are attached to your Frame 2 via magnetic contacts. Gently remove the Snap Frame on your existing device, and place the new Snap Frame on until it "snaps" in place.',
      },
      {
        q: 'Are Snap Frames compatible with the original Frame?',
        a: 'No, Snap Frames are only compatible with Frame 2 devices.',
      },
      {
        q: 'Do I need Plus to access videos and captions for Frame 2?',
        a: 'No, when you purchase a Frame 2 you will be able to share videos and captions for free. Frame Plus includes new features such as Cloud Storage and Doodles.',
      },
    ],
  },
]

export const INTERNATIONAL_FAQ_QUESTIONS = [
  {
    sectionTitle: 'How It Works',
    content: [
      {
        q: 'How do I activate my frame?',
        a: 'To activate your Skylight, visit app.ourskylight.com/register. There, you will create your unique Skylight email address (e.g. grandmabetty@ourskylight.com) and receive a 6-digit activation code to type into the frame.',
      },
      {
        q: 'How do I send photos to Skylight?',
        a: 'Simply draft an email with your unique Skylight email address (e.g. grandmabetty@ourskylight.com) as the recipient and include the photos as attachments. The photos will appear on your Skylight in seconds!',
      },
      {
        q: 'Is email the only way to send a photo?',
        a: 'You can use our Cloud Portal (app.ourskylight.com) or mobile app to send photos and videos.',
      },
      {
        q: 'Can others send photos to Skylight?',
        a: `You can share your unique Skylight email address with others so they can email photos to your Skylight. This is also a great way to have everyone in the family send photos to a loved one's Skylight.`,
      },
      {
        q: 'How many photos can Skylight hold?',
        a: 'Skylight is equipped with 8GB of storage, which is room for up to ~8,000 photos.',
      },
      {
        q: 'How many photos can I send at a time?',
        a: 'When sending via email, you can attach up to 30MB of content to share with Skylight. If you are using our mobile app or online Cloud Portal (app.ourskylight.com), you can share up to 100MB of content at a time.',
      },
      {
        q: 'Can I send photos to multiple frames at once?',
        a: 'Yes! You can send photos to multiple frames by including each unique Skylight address in the recipient line of your email. If you have a Plus account, you can also select multiple frames when sending through the mobile app and our online Cloud Portal (app.ourskylight.com).',
      },
      {
        q: 'Does the Skylight Frame have a battery?',
        a: 'No, Skylight does not have a battery. It will only work if it is plugged in.',
      },
    ],
  },
  {
    sectionTitle: 'Gifting Skylight',
    content: [
      {
        q: 'Can I set up Skylight before gifting it to someone?',
        a: 'Yes! You can set up the Skylight with your personal email address and begin sending photos. Once the frame is connected to Wi-Fi and activated, the photos will automatically begin downloading. Additionally, you will be able to transfer ownership of the frame online at app.ourskylight.com',
      },
      {
        q: `What if the person I'm gifting Skylight to doesn't have Wi-Fi?`,
        a: 'Content will display on Skylight with or without a Wi-Fi connection, but it requires WiFi for initial setup and to receive new photos. This can be achieved with a temporary connection using a mobile hotspot. Any photos that are sent to Skylight while it is disconnected will queue for download until it is connected once more.',
      },
      {
        q: 'Do you have a gift option in your orders?',
        a: 'Skylight comes in a beautiful, ready-to-gift box. We do not yet have gift wrapping available or the ability to add a message to your order on our website, but we are working on it!',
      },
    ],
  },
]
